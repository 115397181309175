import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/LayoutRU'
import BlogRoll from '../components/BlogRoll'


import IcoPDF from '../../static/img/icons/ico_pdf.png'


import WarunkiPrzyjeciaCynkownia from '../../static/pliki/15112018_WARUNKI_CYNKOWANIA.pdf'
import CertyfikatJakosci from '../../static/pliki/CertyfikatJakosciISO9001.pdf'
import Deklaracja_CE_EN_516 from '../../static/pliki/Deklaracja_CE_EN_516.pdf'
import Deklaracja_CE_EN_517 from '../../static/pliki/Deklaracja_CE_EN_517.pdf'

import TUV from '../../static/img/tuv.webp'


import "../components/layout.css"

export const CertyfikatyPageTemplateRU = ({  intro }) => (
         <div>
           <div>
             <div></div>
    </div>
    <section className="section section--gradient antyPadding">
             <div className="container">
        <div className="section AboutP antyPadding">
                 <div className="columns">
            <div className="column is-10 is-offset-1 StoPro">
                     <div className="content">
                       <div className="columns">
                  <div className="column is-12">
                  </div>
                       </div>
                <div className=""><h3 className="has-text-weight-semibold is-size-2 headingCSS5">
                  {intro.heading}<hr className="hr"></hr>
                </h3>
                  <p>В 2000 году высокое качество продукции марки «Экохрон» было подтверждено Сертификатом Системы Управления ISO 9001. Изделия «Экохрон» также получили Технический допуск Института строительной техники.</p>


                  <div className="grid-containerCertyfikaty">
                    <div className="lewyCertyfikaty"><li className="fl">
                      <a href={WarunkiPrzyjeciaCynkownia} target="_blank"  rel="noopener noreferrer"><img alt=" " src={IcoPDF} alt="" className="fvam" /></a>				<a href={WarunkiPrzyjeciaCynkownia} className="fvam" target="_blank"  rel="noopener noreferrer">Warunki przyjęcia elementów do cynkowania ogniowego</a>			</li>
                      <li className="fl">
                        <a href={Deklaracja_CE_EN_517} target="_blank"  rel="noopener noreferrer"><img alt=" " src={IcoPDF} alt="" className="fvam" /></a>				<a href={Deklaracja_CE_EN_517} className="fvam" target="_blank"  rel="noopener noreferrer">DWU - CE - EN 517 Haki bezpieczeństwa</a>			</li>
                      </div>
                    <div className="prawyCertyfikaty"><li className="fl">
                      <a href={Deklaracja_CE_EN_516} target="_blank"  rel="noopener noreferrer"><img alt=" " src={IcoPDF} alt="" className="fvam" /></a>				<a href={Deklaracja_CE_EN_516} className="fvam" target="_blank"  rel="noopener noreferrer">DWU - CE - EN 516 Komunikacja dachowa</a>			</li>
                      <li className="fl">
                        <a href={CertyfikatJakosci} target="_blank"  rel="noopener noreferrer"><img alt=" " src={IcoPDF} alt="" className="fvam" /></a>				<a href={CertyfikatJakosci} className="fvam" target="_blank"  rel="noopener noreferrer">Certyfikat jakości ISO 9001</a>			</li>
                     </div>
                  </div>
                  <div className="imgALL divTUV"><img alt=" " className="imgCertyfikatTUV" src={TUV}></img></div>
                  <ul className="files-list">
                    <div className="clear"></div>
                  </ul> </div>
                       <div className="columns artykulyProd CertyfikatyColumns">
                         <div className="column is-12 has-text-centered">

                         </div>
                       </div>
                       <div className="column is-12 wpisy">
                         <h3 className="has-text-weight-semibold is-size-2">
                           Najnowsze wpisy:
                         </h3>
                         <BlogRoll />
                         <div className="column is-12 has-text-centered">
                           <Link className="btn" to="/blog">
                             Czytaj więcej
                           </Link>
                         </div>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </section>
         </div>
       );

CertyfikatyPageTemplateRU.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  heading: PropTypes.string,
  subheading: PropTypes.string,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  gallery: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const CertyfikatyPageRU = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <CertyfikatyPageTemplateRU
        image={frontmatter.image}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        description={frontmatter.description}
        intro={frontmatter.intro}
        gallery={frontmatter.gallery}
      />
    </Layout>
  )
}

CertyfikatyPageRU.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default CertyfikatyPageRU

export const pageQuery = graphql`
         query CertyfikatyPageTemplateRU {
           markdownRemark(
             frontmatter: { templateKey: { eq: "certyfikaty-pageRU" } }
           ) {
             frontmatter {
               heading
               description
               subheading
               intro {
                 blurbs {
                   image {
                     childImageSharp {
                       fluid(maxWidth: 780, quality: 100) {
                         ...GatsbyImageSharpFluid
                       }
                     }
                   }
                   text
                 }

                 heading
                 description
               }
               gallery {
                 blurbs {
                   image {
                     childImageSharp {
                       fluid(maxWidth: 780, quality: 100) {
                         ...GatsbyImageSharpFluid
                       }
                     }
                   }

                 }

               }
             }
           }
         }
       `;
